var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Breadcrumb',[_c('template',{slot:"restPage"},[_c('el-breadcrumb-item',[_c('router-link',{attrs:{"to":{
            name: 'SatComposePracticeDetail',
            query: { id: _vm.composePractice.id }
          }}},[_vm._v(" "+_vm._s(_vm.composePractice.exam.title)+" ")])],1),_c('el-breadcrumb-item',[_vm._v(" Score Table ")])],1)],2),_c('Heading',{attrs:{"heading":"h4","content":_vm.myTitle}}),_c('hr'),_c('el-alert',{staticClass:"mb-3",attrs:{"title":"After modifying the score table, please click the save button on the right to save it.","type":"warning","effect":"dark","show-icon":"","closable":false}}),_c('div',{staticClass:"search"},[_c('el-select',{staticStyle:{"width":"50%"},attrs:{"filterable":"","remote":"","reserve-keyword":"","placeholder":"keyword","remote-method":_vm.remoteMethod,"loading":_vm.loading},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},_vm._l((_vm.options),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1),(_vm.value)?_c('el-button',{staticClass:"ml-3",attrs:{"type":"success","plain":""},on:{"click":_vm.inputConversion}},[_vm._v(" Import ")]):_vm._e()],1),_c('div',{staticClass:"mt-4"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12",staticStyle:{"padding-right":"0"}},[_c('table',{staticClass:"table table-condensed table-bordered fixed-column",staticStyle:{"text-align":"center","vertical-align":"middle"}},[_c('thead',[_c('tr',[_vm._m(0),_vm._l((_vm.scoreSection),function(title,index){return _c('td',{key:index,class:_vm.isNew ? 'table-new' : '',staticStyle:{"min-width":"30%"}},[_c('b',[_vm._v(" "+_vm._s(title)+" ")]),(_vm.isNew)?_c('table',{staticClass:"table-head"},[_vm._m(1,true)]):_vm._e()])})],2)]),_c('tbody',_vm._l((_vm.score_table[_vm.max_score_table]),function(score,index){return _c('tr',{key:index},[_c('td',{staticClass:"green-td"},[_c('b',[_vm._v(_vm._s(index))])]),_vm._l((_vm.scoreSection),function(title,titleIndex){return _c('td',{key:titleIndex,class:_vm.isNew ? 'table-new' : '',staticStyle:{"background-color":"rgb(255, 255, 255)"}},[(
                    _vm.score_table[title][index] &&
                      index ===
                        _vm.score_table[title][index].correct_question_count
                  )?[(_vm.isNew)?_c('table',{attrs:{"width":"100%"}},[_c('tbody',[_c('tr',[_c('td',[_c('el-input',{attrs:{"type":"number","step":"10"},model:{value:(_vm.score_table[title][index].lower_score),callback:function ($$v) {_vm.$set(_vm.score_table[title][index], "lower_score", $$v)},expression:"score_table[title][index].lower_score"}})],1),_c('td',[_c('el-input',{attrs:{"type":"number","step":"10"},model:{value:(_vm.score_table[title][index].upper_score),callback:function ($$v) {_vm.$set(_vm.score_table[title][index], "upper_score", $$v)},expression:"score_table[title][index].upper_score"}})],1),_c('td',[_c('el-input',{attrs:{"type":"number","step":"10"},model:{value:(_vm.score_table[title][index].score),callback:function ($$v) {_vm.$set(_vm.score_table[title][index], "score", $$v)},expression:"score_table[title][index].score"}})],1)])])]):_c('div',{staticClass:"input-box"},[_c('el-input',{attrs:{"type":"number","step":"10"},model:{value:(_vm.score_table[title][index].score),callback:function ($$v) {_vm.$set(_vm.score_table[title][index], "score", $$v)},expression:"score_table[title][index].score"}})],1)]:_vm._e()],2)})],2)}),0)])])]),_c('div',{staticClass:"save-button"},[_c('el-button',{attrs:{"type":"success"},on:{"click":_vm.updateScoreTable}},[_c('i',{staticClass:"fas fa-save"}),_vm._v(" Save ")])],1)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticStyle:{"width":"10%"},attrs:{"rowspan":"2"}},[_c('b',[_vm._v("#")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tbody',[_c('tr',[_c('td',[_vm._v("Lower Score")]),_c('td',[_vm._v("Upper Score")]),_c('td',[_vm._v("Score")])])])
}]

export { render, staticRenderFns }