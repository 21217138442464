<template>
  <div>
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          <router-link
            :to="{
              name: 'SatComposePracticeDetail',
              query: { id: composePractice.id }
            }"
          >
            {{ composePractice.exam.title }}
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          Score Table
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <Heading heading="h4" :content="myTitle"></Heading>
    <hr />
    <el-alert
      class="mb-3"
      title="After modifying the score table, please click the save button on the right to save it."
      type="warning"
      effect="dark"
      show-icon
      :closable="false"
    >
    </el-alert>
    <div class="search">
      <el-select
        style="width: 50%;"
        v-model="value"
        filterable
        remote
        reserve-keyword
        placeholder="keyword"
        :remote-method="remoteMethod"
        :loading="loading"
      >
        <el-option
          v-for="item in options"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <el-button
        v-if="value"
        class="ml-3"
        type="success"
        @click="inputConversion"
        plain
      >
        Import
      </el-button>
    </div>

    <div class="mt-4">
      <div class="row">
        <!-- <div
          class="col-sm-6"
          style="padding-right:0"
          v-for="group in 2"
          :key="group"
        > -->
        <div
          class="col-sm-12"
          style="padding-right:0"
        >
          <table
            class="table table-condensed  table-bordered fixed-column"
            style="text-align: center;vertical-align: middle;"
          >
            <thead>
              <tr>
                <td rowspan="2" style="width: 10%;">
                  <b>#</b>
                </td>
                <td
                  :class="isNew ? 'table-new' : ''"
                  style="min-width: 30%;"
                  v-for="(title, index) in scoreSection"
                  :key="index"
                >
                  <b>
                    {{ title }}
                  </b>
                  <table v-if="isNew" class="table-head">
                    <tbody>
                      <tr>
                        <td>Lower Score</td>
                        <td>Upper Score</td>
                        <td>Score</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </thead>
            <tbody>
              <!-- <tr
                v-for="(score, index) in score_table[max_score_table]"
                :key="index"
                v-show="
                  group === 1
                    ? index < Math.ceil(score_table[max_score_table].length / 2)
                    : index >=
                      Math.ceil(score_table[max_score_table].length / 2)
                "
              > -->
              <tr
                v-for="(score, index) in score_table[max_score_table]"
                :key="index"
              >
                <td class="green-td">
                  <b>{{ index }}</b>
                </td>
                <td
                  style="background-color: rgb(255, 255, 255);"
                  v-for="(title, titleIndex) in scoreSection"
                  :key="titleIndex"
                  :class="isNew ? 'table-new' : ''"
                >
                  <template
                    v-if="
                      score_table[title][index] &&
                        index ===
                          score_table[title][index].correct_question_count
                    "
                  >
                    <table v-if="isNew" width="100%">
                      <tbody>
                        <tr>
                          <td>
                            <el-input
                              type="number"
                              step="10"
                              v-model="score_table[title][index].lower_score"
                            />
                          </td>
                          <td>
                            <el-input
                              type="number"
                              step="10"
                              v-model="score_table[title][index].upper_score"
                            />
                          </td>
                          <td>
                            <el-input
                              type="number"
                              step="10"
                              v-model="score_table[title][index].score"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <div class="input-box" v-else>
                      <el-input
                        type="number"
                        step="10"
                        v-model="score_table[title][index].score"
                      />
                    </div>
                  </template>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="save-button">
        <el-button type="success" @click="updateScoreTable">
          <i class="fas fa-save"></i>
          Save
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Breadcrumb from "@/components/Breadcrumb";
import SAT from "@/apis/sat.js";

export default {
  metaInfo() {
    return {
      title: `${this.myTitle} - ${this.CompanyName}`
    };
  },

  components: {
    Breadcrumb
  },

  mixins: [],

  props: [],
  data() {
    return {
      options: [],
      loading: false,
      value: null,
      score_table: null,
      practice: null,
      composePractice: null,
      conversionCount: 0
    };
  },
  computed: {
    isCompose() {
      if (this.composePractice) {
        return true;
      } else {
        return false;
      }
    },
    isNew() {
      if (this.composePractice) {
        return this.composePractice.marks[0].name.en === "new";
      } else {
        return this.practice.marks[0].name.en === "new";
      }
    },
    myTitle() {
      let title = "Score Table";
      if (this.isCompose) {
        title = `${this.composePractice.exam.title} - ${title}`;
      } else {
        title = `${this.practice.exam.title} - ${title}`;
      }
      return title;
    },
    id() {
      return Number(this.$route.params.id);
    },
    scoreSection() {
      if (this.score_table) {
        let arr = [];
        for (let key of Object.keys(this.score_table)) {
          arr.push(key);
        }
        return arr;
      } else {
        return [];
      }
    },
    max_score_table() {
      let scoreTable_new = [];
      const scoreTable = this.score_table;
      for (let i in scoreTable) {
        let obj = {
          key: i,
          data: scoreTable[i],
          length: scoreTable[i].length
        };
        scoreTable_new.push(obj);
      }
      let max_score = scoreTable_new.sort(function(a, b) {
        return b.length - a.length;
      })[0];

      return max_score.key;
    }
  },
  watch: {},

  async mounted() {
    await this.getComposePracticeDetail(this.$route.params.practiceId);
    await this.remoteMethod();
  },

  methods: {
    async remoteMethod(query) {
      this.loading = true;
      const res = await SAT.getAllConversions({
        keyword: query,
        conversion_count: this.conversionCount
      });
      this.options = res.sat_score_categories.data;
      this.loading = false;
    },
    async updateScoreTable() {
      let sat_score_conversions = [];
      for (let key in this.score_table) {
        this.score_table[key].forEach(question => {
          sat_score_conversions.push(question);
        });
      }
      if (this.$route.params.id) {
        await SAT.updateConversion(this.$route.params.id, {
          sat_score_conversions: sat_score_conversions
        });
      } else {
        const res = await SAT.createConversion({
          sat_score_category: { name: this.composePractice.exam.title },
          sat_score_conversions: sat_score_conversions
        });
        await SAT.updateComposePractice(this.$route.params.practiceId, {
          compose_practice: {
            exam: {
              title: this.composePractice.exam.title
            },
            scorable_type: "Modules\\SAT\\Entities\\SatScoreCategory",
            scorable_id: res.sat_score_category.id
          }
        });
        this.$router.go(-1);
      }
      this.$notify({
        title: "Success!",
        type: "success"
      });
    },
    async getComposePracticeDetail(id) {
      const res = await SAT.getComposePracticeDetail(id);
      this.composePractice = {
        ...res.compose_practice,
        compose_practice_practices: res.compose_practice.practices
      };
      if (this.isNew) {
        const score_table = {
          ENGLISH: [
            {
              result_section: "ENGLISH",
              correct_question_count: 0,
              lower_score: 0,
              score: 0,
              upper_score: 0
            }
          ],
          MATH: [
            {
              result_section: "MATH",
              correct_question_count: 0,
              lower_score: 0,
              score: 0,
              upper_score: 0
            }
          ]
        };
        let MathCount = 1;
        let EnglishCount = 1;
        this.composePractice.compose_practice_practices.forEach(practice => {
          if (practice.subject_id === 3 || practice.subject_id === 4) {
            practice.exam.exam_questions.forEach(question => {
              score_table["MATH"].push({
                result_section: "MATH",
                correct_question_count: MathCount,
                lower_score: 0,
                score: 0,
                upper_score: 0
              });
              MathCount++;
            });
          } else {
            practice.exam.exam_questions.forEach(question => {
              score_table["ENGLISH"].push({
                result_section: "ENGLISH",
                correct_question_count: EnglishCount,
                lower_score: 0,
                score: 0,
                upper_score: 0
              });
              EnglishCount++;
            });
          }
        });
        this.conversionCount = EnglishCount + MathCount;
        if (this.id) {
          this.getConversions(score_table);
        } else {
          this.score_table = score_table;
        }
      } else {
        const arr = ["READING", "WRITING", "MATH"];
        const score_table = {
          READING: [
            {
              result_section: "READING",
              correct_question_count: 0,
              lower_score: 0,
              score: 0,
              upper_score: 0
            }
          ],
          WRITING: [
            {
              result_section: "WRITING",
              correct_question_count: 0,
              lower_score: 0,
              score: 0,
              upper_score: 0
            }
          ],
          MATH: [
            {
              result_section: "MATH",
              correct_question_count: 0,
              lower_score: 0,
              score: 0,
              upper_score: 0
            }
          ]
        };
        let MathCount = 1;
        let READINGCount = 1;
        let WRITING = 1;
        this.composePractice.compose_practice_practices.forEach(practice => {
          if (practice.subject_id === 3 || practice.subject_id === 4) {
            practice.exam.exam_questions.forEach(question => {
              score_table["MATH"].push({
                result_section: "MATH",
                correct_question_count: MathCount,
                lower_score: 0,
                score: 0,
                upper_score: 0
              });
              MathCount++;
            });
          } else if (practice.subject_id === 1) {
            practice.exam.exam_questions.forEach(question => {
              score_table["READING"].push({
                result_section: "READING",
                correct_question_count: READINGCount,
                lower_score: 0,
                score: 0,
                upper_score: 0
              });
              READINGCount++;
            });
          } else if (practice.subject_id === 2) {
            practice.exam.exam_questions.forEach(question => {
              score_table["WRITING"].push({
                result_section: "WRITING",
                correct_question_count: WRITING,
                lower_score: 0,
                score: 0,
                upper_score: 0
              });
              WRITING++;
            });
          }
        });
        this.conversionCount = WRITING + READINGCount + MathCount;
        if (this.id) {
          this.getConversions(score_table);
        } else {
          this.score_table = score_table;
        }
      }
    },
    async getConversions(score_table) {
      const res = await SAT.getConversions(this.id);
      for (let key in score_table) {
        score_table[key].forEach((question, questionIndex) => {
          res.sat_score_categories.sat_score_conversions.forEach(score => {
            if (
              score.result_section === key &&
              question.correct_question_count === score.correct_question_count
            ) {
              score_table[key][questionIndex] = JSON.parse(
                JSON.stringify(score)
              );
            }
          });
        });
      }
      this.score_table = score_table;
    },
    async inputConversion() {
      const res = await SAT.getConversions(this.value);
      for (let key in this.score_table) {
        this.score_table[key].forEach((question, questionIndex) => {
          res.sat_score_categories.sat_score_conversions.forEach(score => {
            if (
              score.result_section === key &&
              question.correct_question_count === score.correct_question_count
            ) {
              this.score_table[key][questionIndex].lower_score =
                score.lower_score;
              this.score_table[key][questionIndex].upper_score =
                score.upper_score;
              this.score_table[key][questionIndex].score = score.score;
            }
          });
        });
      }
    }
  }
};
</script>

<style scoped>
.input-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
::v-deep .el-input {
  width: auto;
}
.save-button {
  right: 40px;
  top: 160px;
  position: fixed;
  background-color: #ffffff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: var(--themeColor);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  box-shadow: 0 0 6px rgb(0 0 0 / 12%);
  cursor: pointer;
  z-index: 100;
}
.table-head {
  width: 100%;
  margin: 0;
  margin-top: 0.5rem;
  padding: 0;
}
.table-head td {
  border-bottom: none;
}
.table-head td {
  width: 33%;
}
.table-new {
  padding: 0;
}
</style>
